import { CustomError } from 'ts-custom-error';

export enum SDKErrorCode {
  BadParams = 400,
  InternalError = 500,

  InvalidIdToken = 3000,
  InvalidMasterKeySigner = 3001,
  UniPassServerError = 4000,
  InvalidProvider = 4001,
  Web3AuthLoginFailed = 4003,
  InvalidSigner = 4004,
  SimulatingTransactionError = 4005,
  InvalidFeeToken = 4006,
  SendingTransactionError = 4007,
  InvalidSimulatingTransaction = 4008,
  NotSupportChain = 4009,
  UnsupportedOperation = 4010,
}

export class CustomAuthSDKError extends CustomError {
  constructor(public code: SDKErrorCode, public rawMessage: string) {
    super(`Custom Auth SDK Error: [${code}] ${rawMessage}`);
  }
}

export function createInvalidIdTokenError(idToken: string) {
  return new CustomAuthSDKError(
    SDKErrorCode.InvalidIdToken,
    `Invalid Id Token: ${idToken}`
  );
}

export function createInvalidMasterKeySignerError(message: string) {
  return new CustomAuthSDKError(SDKErrorCode.InvalidMasterKeySigner, message);
}

export function createInvalidProviderError(message: string) {
  return new CustomAuthSDKError(SDKErrorCode.InvalidProvider, message);
}

export function createWeb3AuthLoginError(message: string) {
  return new CustomAuthSDKError(SDKErrorCode.Web3AuthLoginFailed, message);
}

export function createBadParamsError(message: string) {
  return new CustomAuthSDKError(SDKErrorCode.BadParams, message);
}

export function createInvalidSignerError(message: string) {
  return new CustomAuthSDKError(SDKErrorCode.InvalidSigner, message);
}

export function createSimulatingTransactionError(message: string) {
  return new CustomAuthSDKError(
    SDKErrorCode.SimulatingTransactionError,
    message
  );
}

export function createSendingTransactionError(message: string) {
  return new CustomAuthSDKError(SDKErrorCode.SendingTransactionError, message);
}

export function createInvalidFeeTokenError(token: string) {
  return new CustomAuthSDKError(
    SDKErrorCode.InvalidFeeToken,
    `Invalid Fee Token: ${token}`
  );
}

export function createInternalError(rawMessage: string) {
  return new CustomAuthSDKError(
    SDKErrorCode.InternalError,
    `Internal Error: ${rawMessage}`
  );
}

export function createNotSupportChainError(chainId: number) {
  return new CustomAuthSDKError(
    SDKErrorCode.NotSupportChain,
    `Not Support Chain: ${chainId}`
  );
}

export function createInvalidParams(message: string) {
  return new CustomAuthSDKError(SDKErrorCode.BadParams, message);
}

export function createUnsupportedOperation(message: string) {
  return new CustomAuthSDKError(SDKErrorCode.UnsupportedOperation, message);
}
