import { CustomError } from 'ts-custom-error';

export class CustomAuthHttpError extends CustomError {
  constructor(public code: number, public rawMessage: string) {
    super(`Custom Auth Http Error: [${code}] [${rawMessage}]`);
  }

  public toJSON() {
    return {
      code: this.code,
      message: this.rawMessage,
      status: 200,
    };
  }
}
