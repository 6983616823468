import { CustomAuthHttpError } from './customAuthHttpError';
import { createNotSupportChainError } from './customAuthSDKError';
import {
  AccountLogin,
  AccountRegister,
  RegisterParams,
  ToBusinessConfig,
  TssKeyGenFinishParams,
  TssKeyGenParams,
  TssRes,
  Web3AuthSig,
} from './interfaces';

declare const _fetch: typeof fetch;

const createPostHTTPRequest = (
  body: object = {},
  headers: object = {}
): object => ({
  method: 'POST',
  headers: { ...headers, 'Content-Type': 'application/json' },
  body: JSON.stringify(body || {}),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildResponse = (res: Response): Promise<any> =>
  res.text().then((text) => {
    if (!res.ok) {
      throw new CustomAuthHttpError(res.status, text);
    }

    const body = JSON.parse(text);

    if (body.statusCode !== 200) {
      throw new CustomAuthHttpError(
        body.statusCode || 500,
        body.message || text
      );
    }

    return body.data;
  });

export class UnipassClient {
  constructor(
    public readonly baseUrl: string,
    public readonly fetch: typeof _fetch
  ) {}

  private getUrl(path: string): string {
    return `${this.baseUrl}${path}`;
  }

  public async login(
    appId: string,
    web3AuthSig: Web3AuthSig,
    expirationInterval = '30d'
  ): Promise<AccountLogin> {
    const res = await this.fetch(
      this.getUrl('/api/v1/custom-auth-account/login'),
      createPostHTTPRequest({
        web3auth: web3AuthSig,
        appId,
        expirationInterval,
      })
    );

    const data = await buildResponse(res);

    return data;
  }

  public async register(input: RegisterParams): Promise<AccountRegister> {
    const {
      keysetJson,
      masterKey: { masterKeyAddress, keyType, keystore },
      web3Auth,
      appId,
    } = input;

    const res = await this.fetch(
      this.getUrl('/api/v1/custom-auth-account/register'),
      createPostHTTPRequest({
        keysetJson,
        masterKey: {
          masterKeyAddress,
          keyType,
          keyStore: keystore,
        },
        web3auth: web3Auth,
        appId,
      })
    );

    const data = await buildResponse(res);

    return data;
  }

  public async tssKeyGenStart(authorization: string): Promise<TssRes> {
    const res = await this.fetch(
      this.getUrl('/api/v1/custom-auth-account/tss/keygen/start'),
      createPostHTTPRequest({}, { Authorization: `Bearer ${authorization}` })
    );

    const data = await buildResponse(res);

    return data;
  }

  public async tssKeyGen(
    authorization: string,
    tssParams: TssKeyGenParams
  ): Promise<TssRes> {
    const res = await this.fetch(
      this.getUrl('/api/v1/custom-auth-account/tss/keygen'),
      createPostHTTPRequest(tssParams, {
        Authorization: `Bearer ${authorization}`,
      })
    );

    const data = await buildResponse(res);

    return data;
  }

  public async tssKeyGenFinish(
    authorization: string,
    tssParams: TssKeyGenFinishParams
  ): Promise<TssRes> {
    const res = await this.fetch(
      this.getUrl('/api/v1/custom-auth-account/tss/keygen/finish'),
      createPostHTTPRequest(
        {
          localKeyAddress: tssParams.tssKeyAddress,
          userId: tssParams.userId,
          sessionId: tssParams.sessionId,
        },
        { Authorization: `Bearer ${authorization}` }
      )
    );

    const data = await buildResponse(res);

    return data;
  }

  public async config(
    appId: string,
    chainId: number
  ): Promise<ToBusinessConfig> {
    const res = await this.fetch(
      this.getUrl('/api/v1/custom-auth-account/config'),
      createPostHTTPRequest({
        appId,
        chainId,
      })
    );

    const data = await buildResponse(res);

    if (!data.env) {
      data.env = 'mainnet';
    }

    if (!data.unipassRelayerUrl) {
      throw createNotSupportChainError(chainId);
    }

    return data;
  }

  public async updateStatus(
    appId: string,
    chainId: number,
    address: string
  ): Promise<void> {
    const res = await this.fetch(
      this.getUrl('/api/v1/custom-auth-account/status/update'),
      createPostHTTPRequest({
        appId,
        chainId,
        address,
      })
    );

    await buildResponse(res);
  }
}
